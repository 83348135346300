<template>
  <div>
    <base-card :loading="cardLoading">
      <div>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              small
              dark
              color="primary"
              @click="(title = 'Add'), openDialog()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Staff
            </v-btn>
            <div>
              <v-btn
                class="ma-2"
                small
                dark
                color="danger"
                v-if="selected != ''"
                @click="dialogDelete = true"
              >
                <v-icon small>mdi-delete</v-icon>
                delete
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="6" lg="8">Staff</v-col>
            <v-col cols="6" lg="2"
              ><v-text-field
                class="mt-n4"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
            <v-col cols="12" lg="2">
              <v-select
                class="mt-n2 mb-n13"
                v-model="findBranch"
                :items="branchFilter"
                item-text="name"
                item-value="branch_id"
                dense
                solo
              ></v-select
            ></v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="newStaffList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          item-key="user_id"
          hide-default-footer
          @click:row="handleClick"
          show-select
        >
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :readonly="item.role == 0 ? true : false"
              :disabled="item.role == 0 ? true : false"
              :ripple="false"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.phone="{ item }">
            <div v-if="item.phone == ''">-</div>
            <div v-else>{{ item.phone }}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <div v-if="item.role == 2 && item.password == ''">
              <v-tooltip left max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="
                      sendEmail(item.user_id), (loadingDialog = true)
                    "
                  >
                    mdi-email-fast
                  </v-icon>
                </template>
                <span>Send Password Setting</span>
              </v-tooltip>
            </div>
            <div v-else-if="item.role == 2 && item.password != ''">
              <v-tooltip left max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-email-check
                  </v-icon>
                </template>
                <span>Done Setting Password</span>
              </v-tooltip>
            </div>
            <div v-else>None</div>
          </template>

          <template v-slot:item.role="{ item }">
            <div v-if="item.role == 0">Owner</div>
            <div v-else-if="item.role == 1">Cashier</div>
            <div v-else-if="item.role == 2">Manager</div>
            <div v-else>Waiter</div>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="item.status == 0 ? 'success' : 'warning'"
              dark
              small
            >
              <span v-if="item.status == 0" class="text-body-2">Active</span>
              <span v-else class="text-body-2">Disabled</span>
            </v-chip>
          </template>
        </v-data-table>
        <div class="text-center py-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </base-card>

    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text>
          <div class="mb-5">
            <span class="text-h6">{{ title }} Staff</span>
          </div>
          <v-text-field
            prepend-icon="mdi-account"
            label="Staff Name"
            v-model="sName"
            :error-messages="sNameErrors"
            required
            @input="$v.sName.$touch()"
            @blur="$v.sName.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-phone"
            label="Phone"
            v-model="sPhone"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-email"
            label="Email"
            v-model="sEmail"
            autofocus
            :error-messages="sEmailErrors"
            :disabled="ownerLock"
            required
            @input="$v.sEmail.$touch()"
            @blur="$v.sEmail.$touch()"
          ></v-text-field>
          <v-select
            v-model="selectBranch"
            :items="branchList"
            :disabled="ownerLock"
            multiple
            chips
            prepend-icon="mdi-store"
            item-text="name"
            item-value="branch_id"
            label="Select Branch"
            :error-messages="selectBranchErrors"
            required
            @input="$v.selectBranch.$touch()"
            @blur="$v.selectBranch.$touch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleForBranch">
                <v-list-item-action>
                  <v-icon :color="selectBranch.length > 0 ? 'primary' : ''">
                    {{ iconForBranch }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-select
            v-if="roleLock"
            v-model="selectRole"
            :items="roleItems"
            label="Select Role"
            prepend-icon="mdi-briefcase"
            :error-messages="selectRoleErrors"
            required
            @input="$v.selectRole.$touch()"
            @blur="$v.selectRole.$touch()"
            @change="generateRandomPin"
          ></v-select>

          <v-text-field
            v-if="displayOwnerRole != ''"
            prepend-icon="mdi-briefcase"
            label="Role"
            v-model="displayOwnerRole"
            :disabled="ownerLock"
          ></v-text-field>

          <div v-if="selectRole != ''">
            <v-icon> mdi-lock </v-icon>
            <span class="ml-3 mr-1 text-subtitle-1">Pin</span>
            <v-tooltip right max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mb-1"
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span
                >This pin will be use when login on POS system and some access
                restriction.</span
              >
            </v-tooltip>
            <v-otp-input
              v-model="sPin"
              :error-messages="sPinErrors"
              required
              @input="$v.sPin.$touch()"
              @blur="$v.sPin.$touch()"
              class="mx-7 my-1"
              type="number"
            ></v-otp-input>

            <v-checkbox
              v-model="allowLogin"
              label="Send Password Setting"
              color="primary"
              hide-details
              v-if="selectRole == 'Manager' && title == 'Add'"
            >
              <template v-slot:append>
                <v-tooltip left max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >An email will send to the staff email. Staff able to set
                    thier password to login the office portal</span
                  >
                </v-tooltip>
              </template>
            </v-checkbox>
          </div>
          <v-switch
            class="mb-n6"
            v-model="statusEditPricePin"
            label="Edit Price & Quantity Permission"
            :disabled="ownerLock"
          ></v-switch>

          <v-switch
            class="mb-n6"
            v-model="statusRefundPermission"
            label="Refund Permission"
            :disabled="ownerLock"
          ></v-switch>

          <v-switch
            class="mb-n6"
            v-model="statusCashDrawerPermission"
            label="Cash Drawer Permission"
            :disabled="ownerLock"
          ></v-switch>

          <v-switch
            class="mb-n6"
            v-model="statusSettlementPermission"
            label="Settlement Permission"
            :disabled="ownerLock"
          ></v-switch>

          <v-switch
            class="mb-n6"
            v-model="statusReportPermission"
            label="Report Permission"
            :disabled="ownerLock"
          ></v-switch>

          <v-switch
            v-model="statusToggle"
            label="Disable Account"
            v-if="title == 'Edit' && selectRole != 'Owner'"
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit' && selectRole != 'Owner'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.prevent="dialogAction" text>
            save
          </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteCustomer" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Sending
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  minValue,
  email,
} from "vuelidate/lib/validators";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  mixins: [validationMixin],
  validations: {
    sName: { required },
    // sPhone: {
    //   minLength: minLength(10),
    //   maxLength: maxLength(11),
    //   minValue: minValue(0),
    // },
    sEmail: {
      email,
      required,
      exist(value) {
        if (value === "") return true;
        if (this.title == "Add") {
          return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append("checkEmailExist", "done");
            params.append("email", this.sEmail);

            axios({
              method: "post",
              url: this.domain + "/user/index.php",
              data: params,
            })
              .then((response) => {
                const email = response.data.status;
                resolve(email == 2);
              })
              .catch(() => reject(false));
          });
        } else {
          return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append("checkEmailExistForUpdate", "done");
            params.append("email", this.sEmail);
            params.append("user_id", this.selectUser);
            axios({
              method: "post",
              url: this.domain + "/user/index.php",
              data: params,
            })
              .then((response) => {
                const email = response.data.status;
                resolve(email === 2);
              })
              .catch(() => reject(false));
          });
        }
      },
    },
    selectBranch: { required },
    selectRole: { required },
    sPin: { required, minLength: minLength(6) },
  },

  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      snackbar: false,
      text: "",
      snackbarColor: "",
      timeout: 1900,
      companyID: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Contacts", value: "phone", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role", sortable: false },
        { text: "Status", value: "status", align: "center", sortable: false },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      staffList: [],
      cardLoading: true,
      search: "",
      selected: [],
      title: "",
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      roleItems: ["Cashier", "Manager", "Waiter"],
      branchList: [],
      sName: "",
      sPhone: "",
      sEmail: "",
      selectRole: "",
      selectBranch: "",
      sPin: "",
      allowLogin: false,
      statusToggle: 0,
      statusEditPricePin: 0,
      statusRefundPermission: 0,
      statusCashDrawerPermission: 0,
      statusSettlementPermission: 0,
      statusReportPermission: 0,
      selectUser: "",
      password: "",
      ownerLock: false,
      roleLock: true,
      displayOwnerRole: "",
      dialogDelete: false,
      findBranch: "0",
      loadingDialog: false,
    };
  },
  watch: {
    statusToggle() {
      this.changeStatusFormat();
    },
    statusEditPricePin() {
      this.changeStatusEditPriceFormat();
    },

    statusRefundPermission() {
      this.changeStatusRefundPermissionFormat();
    },

    statusCashDrawerPermission() {
      this.changeStatusCashDrawerPermissionFormat();
    },

    statusSettlementPermission() {
      this.changeStatusSettlementPermissionFormat();
    },

    statusSettlementPermission() {
      this.changeStatusSettlementPermissionFormat();
    },

    statusReportPermission() {
      this.changeStatusReportPermissionFormat();
    },

    findBranch() {
      if (this.findBranch == "0") {
        this.getAllStaff();
      } else {
        const params = new URLSearchParams();
        params.append("findBranch", "done");
        params.append("branch_id", this.findBranch);
        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.staffList = response.data.user;
            } else {
              this.staffList = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  computed: {
    sNameErrors() {
      const errors = [];
      if (!this.$v.sName.$dirty) return errors;
      !this.$v.sName.required && errors.push("staff name is required");
      return errors;
    },
    // sPhoneErrors() {
    //   const errors = [];
    //   if (!this.$v.sPhone.$dirty) return errors;
    //   !this.$v.sPhone.minValue && errors.push("Phone cannot be negative value");
    //   !this.$v.sPhone.minLength && errors.push("Phone must at least 10 number");
    //   !this.$v.sPhone.maxLength &&
    //     errors.push("Phone maxinmum consist of 11 number");
    //   return errors;
    // },
    sEmailErrors() {
      const errors = [];
      if (!this.$v.sEmail.$dirty) return errors;
      !this.$v.sEmail.required && errors.push("staff email is required");
      !this.$v.sEmail.email && errors.push("Please enter an valid email");
      !this.$v.sEmail.exist && errors.push("Email already exists");

      return errors;
    },

    selectBranchErrors() {
      const errors = [];
      if (!this.$v.selectBranch.$dirty) return errors;
      !this.$v.selectBranch.required &&
        errors.push("Please select the branch for staff");
      return errors;
    },

    selectRoleErrors() {
      const errors = [];
      if (!this.$v.selectRole.$dirty) return errors;
      !this.$v.selectRole.required &&
        errors.push("Please select the role for staff");
      return errors;
    },

    sPinErrors() {
      const errors = [];
      if (!this.$v.sPin.$dirty) return errors;
      !this.$v.sPin.required && errors.push("Please set your pin");
      !this.$v.sPin.minLength && errors.push("Please set 6 digit pin");
      return errors;
    },

    getAllBranchID() {
      var array = [];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i].branch_id);
      }
      return array;
    },

    selectAllBranch() {
      return this.selectBranch.length === this.getAllBranchID.length;
    },

    selectSomeBranch() {
      return this.selectBranch.length > 0 && !this.selectAllBranch;
    },

    iconForBranch() {
      if (this.selectAllBranch) return "mdi-close-box";
      if (this.selectSomeBranch) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    newStaffList() {
      return this.staffList.map((x) => ({ ...x, isSelectable: x.role != 0 }));
    },
    branchFilter() {
      var array = [{ name: "All Branch", branch_id: "0" }];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i]);
      }
      return array;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllStaff();
    this.getCompanyBranch();
  },

  methods: {
    getAllStaff() {
      const params = new URLSearchParams();
      params.append("getAllStaff", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.staffList = response.data.user;
            this.cardLoading = false;
          } else {
            this.staffList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openDialog() {
      if (this.title == "Add") {
        this.$v.$reset();
        this.sName = "";
        this.sPhone = "";
        this.sEmail = "";
        this.selectBranch = "";
        this.selectRole = "";
        this.sPin = "";
        this.ownerLock = false;
        this.allowLogin = false;
        this.roleLock = true;
        this.displayOwnerRole = "";
        this.password = "";
        this.dialog = true;
        this.statusRefundPermission = 0;
        this.statusCashDrawerPermission = 0;
        this.statusSettlementPermission = 0;
        this.statusReportPermission = 0;
        this.statusEditPricePin = 0;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },

    toggleForBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranch) {
          this.selectBranch = [];
        } else {
          this.selectBranch = this.getAllBranchID.slice();
        }
      });
    },

    handleClick(item) {
      if (item.role == 0) {
        this.selectUser = item.user_id;
        this.sName = item.name;
        this.sPhone = item.phone;
        this.sEmail = item.email;
        this.ownerLock = true;
        this.selectRole = "Owner";
        this.displayOwnerRole = "Owner";
        this.roleLock = false;
        this.sPin = item.pos_pin;
        this.password = item.password;
        this.statusToggle = item.status;
        this.statusEditPricePin = item.edit_price_without_pin;
        this.statusRefundPermission = item.refund_permission;
        this.statusCashDrawerPermission = item.cash_drawer_permission;
        this.statusSettlementPermission = item.settlement_permission;
        this.statusReportPermission = item.report_permission;
        this.allowLogin = false;
        this.getBranchForUser();
        this.title = "Edit";
        this.openDialog();
      } else {
        this.selectUser = item.user_id;
        this.sName = item.name;
        this.sPhone = item.phone;
        this.sEmail = item.email;
        this.ownerLock = false;
        this.roleLock = true;
        this.displayOwnerRole = "";
        if (item.role == 1) {
          this.selectRole = "Cashier";
        } else if (item.role == 2) {
          this.selectRole = "Manager";
        } else {
          this.selectRole = "Waiter";
        }
        this.sPin = item.pos_pin;
        this.allowLogin = false;
        this.password = item.password;
        this.statusToggle = item.status;
        this.statusEditPricePin = item.edit_price_without_pin;
        this.statusRefundPermission = item.refund_permission;
        this.statusCashDrawerPermission = item.cash_drawer_permission;
        this.statusSettlementPermission = item.settlement_permission;
        this.statusReportPermission = item.report_permission;
        this.getBranchForUser();
        this.title = "Edit";
        this.openDialog();
      }
    },

    getBranchForUser() {
      const params = new URLSearchParams();
      params.append("getBranchForUser", "done");
      params.append("user_id", this.selectUser);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.selectBranch = response.data.branch;
          } else {
            this.selectBranch = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generateRandomPin() {
      if (this.title == "Add") {
        var randomize = require("randomatic");
        this.sPin = randomize("0", 6);
      }
    },

    changeStatusFormat() {
      if (this.statusToggle == true) {
        this.statusToggle = 1;
      } else {
        this.statusToggle = 0;
      }
    },

    changeStatusEditPriceFormat() {
      if (this.statusEditPricePin == true) {
        this.statusEditPricePin = 1;
      } else {
        this.statusEditPricePin = 0;
      }
    },

    changeStatusRefundPermissionFormat() {
      if (this.statusRefundPermission == true) {
        this.statusRefundPermission = 1;
      } else {
        this.statusRefundPermission = 0;
      }
    },

    changeStatusCashDrawerPermissionFormat() {
      if (this.statusCashDrawerPermission == true) {
        this.statusCashDrawerPermission = 1;
      } else {
        this.statusCashDrawerPermission = 0;
      }
    },
    changeStatusSettlementPermissionFormat() {
      if (this.statusSettlementPermission == true) {
        this.statusSettlementPermission = 1;
      } else {
        this.statusSettlementPermission = 0;
      }
    },

    changeStatusReportPermissionFormat() {
      if (this.statusReportPermission == true) {
        this.statusReportPermission = 1;
      } else {
        this.statusReportPermission = 0;
      }
    },

    deleteCustomer() {
      if (this.selected != "") {
        const array = [];
        for (let i = 0; i < this.selected.length; i++) {
          array.push(this.selected[i].user_id);
        }
        const params = new URLSearchParams();
        params.append("deleteMultiUser", "done");
        params.append("array_user_id", JSON.stringify(array));
        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.selected = [];
              this.dialogDelete = false;
              this.getAllStaff();
              for (let i = 0; i < array.length; i++) {
                this.getBranchForUserForNotification(array[i]);
              }
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("deleteUser", "done");
        params.append("user_id", this.selectUser);
        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.dialogDelete = false;
              this.getAllStaff();
              this.getBranchForUserForNotification(this.selectUser);
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBranchForUserForNotification(user_id) {
      const params = new URLSearchParams();
      params.append("getBranchForUserForNotification", "done");
      params.append("user_id", user_id);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.branch;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendEmail(user_id) {
      const params = new URLSearchParams();
      params.append("sendEmail", "done");
      params.append("user_id", user_id);
      axios({
        method: "post",
        url: this.domain + "/email/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.loadingDialog = false;
            this.text = "Successfully send mail to staff for password setting";
            this.snackbar = true;
            this.snackbarColor = "success";
          } else if (response.data.status == "2") {
            this.text = "Please try again later";
            this.loadingDialog = false;
            this.snackbar = true;
            this.snackbarColor = "error";
          } else {
            this.text =
              "Please do not send rapidly. Please try again after 1 minutes";
            this.snackbar = true;
            this.snackbarColor = "warning";
            this.loadingDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addUser", "done");
          params.append("company_id", this.companyID);
          params.append("name", this.sName);
          params.append("phone", this.sPhone);
          params.append("email", this.sEmail);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          if (this.selectRole == "Cashier") {
            params.append("role", 1);
          } else if (this.selectRole == "Manager") {
            params.append("role", 2);
          } else {
            params.append("role", 3);
          }
          params.append("pos_pin", this.sPin);
          params.append("status_edit_price", this.statusEditPricePin);

          params.append("refund_permission", this.statusRefundPermission);
          params.append(
            "cash_drawer_permission",
            this.statusCashDrawerPermission
          );
          params.append(
            "settlement_permission",
            this.statusSettlementPermission
          );
          params.append("report_permission", this.statusReportPermission);

          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                if (this.allowLogin == true) {
                  // calling send email function
                  this.sendEmail(response.data.user_id);
                }
                this.getAllStaff();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else if (response.data.status == "2") {
                this.text = "Please try another pin.";
                this.snackbar = true;
                this.snackbarColor = "warning";
              } else {
                this.text = "Something went wrong. Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editUser", "done");
          params.append("user_id", this.selectUser);
          params.append("company_id", this.companyID);
          params.append("name", this.sName);
          params.append("phone", this.sPhone);
          params.append("email", this.sEmail);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));

          if (this.selectRole == "Cashier") {
            params.append("role", 1);
          } else if (this.selectRole == "Manager") {
            params.append("role", 2);
          } else if (this.selectRole == "Waiter") {
            params.append("role", 3);
          } else {
            params.append("role", 0);
          }
          params.append("pos_pin", this.sPin);

          params.append("status_edit_price", this.statusEditPricePin);
          params.append("refund_permission", this.statusRefundPermission);
          params.append(
            "cash_drawer_permission",
            this.statusCashDrawerPermission
          );
          params.append(
            "settlement_permission",
            this.statusSettlementPermission
          );
          params.append("report_permission", this.statusReportPermission);

          params.append("status", this.statusToggle);

          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllStaff();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else if (response.data.status == "2") {
                this.text = "Please try another pin.";
                this.snackbar = true;
                this.snackbarColor = "warning";
              } else {
                this.text = "Something went wrong. Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
